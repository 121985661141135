var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex space-x-3 items-end text-white"},[_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.poll.answerOptions.min)+" "+_vm._s(_vm.poll.answerOptions.unit)+" ")]),_c('p',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(_vm.poll.answerOptions.defaultValue)+" "+_vm._s(_vm.poll.answerOptions.unit)+" ")]),_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.poll.answerOptions.max)+" "+_vm._s(_vm.poll.answerOptions.unit)+" ")])]),_c('div',{staticClass:"flex-col space-y-3 text-white"}),(!_vm.isFilterPoll)?_c('div',{staticClass:"flex flex-col items-center space-y-4 text-white"},[_c('p',{staticClass:"text-2xl"},[_vm._v(" "+_vm._s(_vm.pollAnswer.avg || _vm.poll.answerOptions.defaultValue)+" "+_vm._s(_vm.poll.answerOptions.unit)+" ")]),_c('input',{attrs:{"type":"range","disabled":"","min":_vm.poll.answerOptions.min > 1
          ? _vm.poll.answerOptions.min
          : _vm.poll.answerOptions.min * 10,"max":_vm.poll.answerOptions.min > 1
          ? _vm.poll.answerOptions.max
          : _vm.poll.answerOptions.max * 10,"autoComplete":"off"},domProps:{"value":_vm.poll.answerOptions.min > 1 ? _vm.pollAnswer.avg : _vm.pollAnswer.avg * 10}})]):_vm._e(),(_vm.isFilterPoll)?_c('div',{staticClass:"flex flex-col items-center space-y-4 text-white"},[_c('p',{staticClass:"text-2xl"},[_vm._v(" "+_vm._s(_vm.averageValue || _vm.poll.answerOptions.defaultValue)+" "+_vm._s(_vm.poll.answerOptions.unit)+" ")]),_c('input',{attrs:{"type":"range","disabled":"","min":_vm.poll.answerOptions.min > 1
          ? _vm.poll.answerOptions.min
          : _vm.poll.answerOptions.min * 10,"max":_vm.poll.answerOptions.min > 1
          ? _vm.poll.answerOptions.max
          : _vm.poll.answerOptions.max * 10,"autoComplete":"off"},domProps:{"value":_vm.poll.answerOptions.min > 1 ? _vm.averageValue : _vm.averageValue * 10}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }