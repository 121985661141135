













































































































































































import Vue from 'vue';
import PollList from '@/components/Presenter/PollList.vue';
import FilterDropdown from '@/components/Presenter/FilterDropdown.vue';
import FilterPoll from '@/components/Presenter/FilterPoll.vue';
import { PresenterPoll } from '@/api/interfaces.api';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    PollList,
    FilterDropdown,
    FilterPoll,
  },
  data() {
    return {
      drag: false,
      sortedPollls: [] as PresenterPoll[],
      isFilterPollDropdownActive: false,
      isPollDropdownActive: false,
      pollFilters: [] as string[],
      isFilterActive: false,
      currentFilterPoll: { id: '', title: '' },
      currentPoll: { id: '', title: '' },
    };
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPollResults',
      presenterSessionId
    );
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      polls: 'presenter/getSessionPolls',
      loading: 'presenter/getLoadingState',
      tvData: 'presenter/getTVData',
    }),
    computedTitleValue(): string {
      return (
        (this.currentSession.labels && this.currentSession.labels['polls']) ||
        this.$t('components.presenter.polls')
      );
    },
    filterPollNavItems(): any[] {
      const filterPolls = this.polls.filter((poll: any) => poll.isFilterPoll);
      if (filterPolls) {
        return filterPolls.map((poll: any) => {
          return {
            id: poll.id,
            title: 'internalTitle' in poll ? poll.internalTitle : poll.title,
          };
        });
      }
      return [];
    },
    pollNavItems(): any[] {
      const otherPolls = this.polls.filter((poll: any) => !poll.isFilterPoll);
      if (otherPolls) {
        return otherPolls.map((poll: any) => {
          return {
            id: poll.id,
            title: 'internalTitle' in poll ? poll.internalTitle : poll.title,
          };
        });
      }
      return [];
    },
    computedFilteredPolls(): any[] {
      const firstPoll = this.polls.filter(
        (poll: any) => poll.id === this.currentFilterPoll.id
      )[0];
      const secondPoll = this.polls.filter(
        (poll: any) => poll.id === this.currentPoll.id
      )[0];

      return [firstPoll, secondPoll];
    },
  },
  methods: {
    fetchData() {
      const presenterSessionId = this.$route.params.presenterSessionId;
      this.$store.dispatch('presenter/fetchPollResults', presenterSessionId);
    },
    selectFilterPoll(filterPoll: any) {
      this.isFilterPollDropdownActive = false;
      this.currentFilterPoll = filterPoll;
      this.isFilterActive = true;

      this.pollFilters.push(filterPoll.id as string);
    },
    selectPoll(poll: any) {
      this.isPollDropdownActive = false;
      this.currentPoll = poll;
      this.isFilterActive = true;

      this.pollFilters.push(poll.id as string);
    },
    removeFilter(type: string, pollId: string) {
      this.pollFilters = this.pollFilters.filter((id) => id !== pollId);

      if (this.pollFilters.length == 0) {
        this.isFilterActive = false;
      }

      if (type === 'filterPoll') {
        this.currentFilterPoll = { id: '', title: '' };
      } else {
        this.currentPoll = { id: '', title: '' };
      }
    },
  },
});
