




























































































































import Vue from 'vue';
import SliderPollAnswers from './SliderPollAnswers.vue';
import PollResults from '@/components/Presenter/PollResults.vue';
import TVButton from '@/components/Presenter/TVButton.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    SliderPollAnswers,
    PollResults,
    TVButton,
  },
  async mounted() {
    const sessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch('presenter/fetchGroupPollResults', {
      sessionId,
      polls: this.polls,
    });
  },

  props: {
    polls: {
      type: Array,
      required: true,
    },
    currentTVMode: {
      type: String,
      required: true,
    },
    shareToken: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      groupResult: 'presenter/getPollGroupResult',
      getPollAnswers: 'presenter/getPollAnswers',
    }),
    filterPoll(): any {
      return this.polls[0];
    },
    mainPoll(): any {
      return this.polls[1];
    },
    computedVotes(): number {
      let votes = 0;
      if (
        this.groupResult &&
        'meta' in this.groupResult &&
        'opinionFlow' in this.groupResult['meta']
      ) {
        const opinionFlow = this.groupResult['meta']['opinionFlow'];
        Object.keys(opinionFlow).map((key) => {
          const vote = Object.values(opinionFlow[key]).reduce(
            (a: any, b: any) => a + b
          );
          votes += vote as number;
        });
      }
      return votes;
    },
    groupedTVData(): any {
      const tmp = {} as any;
      const referencePollValues = {} as any;

      this.filterPoll.answerOptions.choices.map((choice: any) => {
        if (this.mainPoll.type === 'CHOICE') {
          tmp[choice.id] = this.pollResultsForChoice(choice.id);
        } else {
          tmp[choice.id] = {
            averageValue: this.averageValueForChoice(choice.id),
            min: this.mainPoll.answerOptions.min,
            max: this.mainPoll.answerOptions.max,
            unit: this.mainPoll.answerOptions.unit,
          };
        }

        referencePollValues[choice.id] =
          'tvLabel' in choice ? choice.tvLabel : choice.value;
      });

      return {
        poll: this.mainPoll,
        results: {
          firstLevelResults: this.getPollAnswers[this.filterPoll.id]
            ? this.getPollAnswers[this.filterPoll.id].choices
            : {},
          secondLevelResults: tmp,
          referencePollValues,
        },
      };
    },
    computedGroupId(): string {
      return `poll.group+${this.filterPoll.id}+${this.mainPoll.id}`;
    },
  },
  methods: {
    togglePollAnimation(pollId: string): void {
      this.$store.dispatch('presenter/togglePollAnimation', {
        pollId,
      });
    },
    resetPollAnimation(pollId: string): void {
      this.$store.dispatch('presenter/resetPollAnimation', {
        pollId,
      });
    },
    syncGroupPollResults(pollId: string): void {
      const results = this.groupedTVData;
      this.$store.dispatch('presenter/syncPollResults', {
        tvResults: results,
        pollId,
      });
    },
    averageValueForChoice(choiceId: string): number {
      if (
        this.groupResult &&
        'meta' in this.groupResult &&
        'opinionFlow' in this.groupResult['meta'] &&
        choiceId in this.groupResult['meta']['opinionFlow']
      ) {
        const choiceResult = this.groupResult['meta']['opinionFlow'][choiceId];
        let sumOfValues = 0;
        for (const key in choiceResult) {
          const i = parseFloat(key);
          sumOfValues += choiceResult[key] * i;
        }
        const count: any = Object.values(choiceResult).reduce(
          (a: any, b: any) => a + b
        );

        return Math.floor((sumOfValues / count) * 100) / 100;
      }

      return 0;
    },

    pollResultsForChoice(choiceId: string): any {
      const result = {} as any;

      this.filterPoll.answerOptions.choices.map((el: any) => {
        if (
          this.groupResult &&
          'meta' in this.groupResult &&
          'opinionFlow' in this.groupResult['meta'] &&
          choiceId in this.groupResult['meta']['opinionFlow']
        ) {
          const choiceResult = this.groupResult['meta']['opinionFlow'][
            choiceId
          ];
          const countedVotes: any = Object.values(choiceResult).reduce(
            (a: any, b: any) => a + b
          );

          if (el.id in choiceResult) {
            result[el.id] = {
              percentage:
                Math.floor((choiceResult[el.id] / countedVotes) * 10000) / 100,
              count: choiceResult[el.id],
            };
            return;
          }
        }
        result[el.id] = {
          percentage: 0,
          count: 0,
        };
      });
      return result;
    },
    openTVPollLink(pollId: string) {
      window.open(
        `${process.env.VUE_APP_BASE_TV_OUT}j/${this.shareToken}/groups/${pollId}/chart`,
        '_blank'
      );
    },
  },
});
