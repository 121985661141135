var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-8"},_vm._l((_vm.poll.answerOptions.sliders),function(slider,i){return _c('div',{key:i,staticClass:"flex flex-col items-start justify-start"},[_c('h3',{staticClass:"text-xl font-bold text-white"},[_vm._v(_vm._s(slider.title))]),_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"flex space-x-3 items-end text-white"},[_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(slider.min))]),_c('p',{staticClass:"text-xl font-bold"},[_vm._v(_vm._s(slider.defaultValue))]),_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(slider.max))])]),_c('div',{staticClass:"flex flex-col items-center space-y-4 text-white"},[_c('p',{staticClass:"text-2xl"},[_vm._v(" "+_vm._s(_vm.pollAnswer && _vm.pollAnswer.sliders && slider.id in _vm.pollAnswer.sliders ? _vm.pollAnswer.sliders[slider.id].avg || 0 : slider.defaultValue)+" ")]),_c('input',{attrs:{"type":"range","disabled":"","min":slider.min > 1 ? slider.min : slider.min * 10,"max":slider.min > 1 ? slider.max : slider.max * 10,"autoComplete":"off"},domProps:{"value":_vm.pollAnswer &&
            _vm.pollAnswer.sliders &&
            slider.id in _vm.pollAnswer.sliders
              ? slider.min > 1
                ? _vm.pollAnswer.sliders[slider.id].avg
                : _vm.pollAnswer.sliders[slider.id].avg * 10
              : slider.min > 1
              ? slider.defaultValue
              : slider.defaultValue * 10}})])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }