





















































import Vue from 'vue';
export default Vue.extend({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    navItems: {
      type: Array,
      required: true,
    },
  },
});
