




































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SliderPollAnswers',
  props: {
    poll: {
      type: Object,
      required: true,
    },
    isFilterPoll: {
      type: Boolean,
      default: false,
      required: false,
    },
    averageValue: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getPollAnswers: 'presenter/getPollAnswers',
    }),
    pollAnswer(): any {
      if (this.poll.id in this.getPollAnswers) {
        return this.getPollAnswers[this.poll.id];
      }
      return {};
    },
  },
  methods: {},
});
