






































































































































































































































































































































import Vue from 'vue';
import SliderPollAnswers from './SliderPollAnswers.vue';
import PollResults from '@/components/Presenter/PollResults.vue';
import MultiSliderPollAnswers from './MultiSliderPollAnswers.vue';
import TVButton from '@/components/Presenter/TVButton.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    SliderPollAnswers,
    MultiSliderPollAnswers,
    PollResults,
    TVButton,
  },
  props: {
    polls: {
      type: Array,
      required: true,
    },
    currentTVMode: {
      type: String,
      required: true,
    },
    shareToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDestructivePollActions: false,
    };
  },
  mounted() {
    this.showDestructivePollActions =
      localStorage.getItem('showDestructivePollActions') === 'true';
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      getPollAnswers: 'presenter/getPollAnswers',
      userTheme: 'auth/getUserTheme',
      showTVControls: 'auth/getUserShowTVControls',
    }),
  },
  methods: {
    resetPoll(pollId: string) {
      if (
        confirm(this.$t('components.presenter.pollListResetConfirm') as string)
      ) {
        this.$store.dispatch('presenter/resetPoll', {
          pollId,
        });
      }
    },
    togglePollPaused(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollPaused', {
        pollId,
        state,
      });
    },
    removePoll(pollId: string) {
      if (
        confirm(this.$t('components.presenter.pollListDeleteConfirm') as string)
      ) {
        this.$store.dispatch('presenter/removePoll', {
          pollId,
        });
      }
    },
    editPoll(pollId: string) {
      this.$router.push({
        name: 'Presenter Session Edit Poll',
        params: { pollId },
      });
    },
    togglePollVisibility(
      pollId: string,
      state: boolean,
      activatePollTimer: boolean
    ) {
      this.$store.dispatch('presenter/togglePollVisibility', {
        pollId,
        state,
        activatePollTimer,
      });
    },
    togglePollAnimation(pollId: string) {
      this.$store.dispatch('presenter/togglePollAnimation', {
        pollId,
      });
    },
    togglePollResults(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollResults', {
        pollId,
        state,
      });
    },
    togglePollTimer(pollId: string) {
      this.$store.dispatch('presenter/togglePollTimer', {
        pollId,
      });
    },
    togglePollStatisticsVisibility(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollStatisticsVisibility', {
        pollId,
        state,
      });
    },
    resetPollAnimation(pollId: string) {
      this.$store.dispatch('presenter/resetPollAnimation', {
        pollId,
      });
    },
    getPollAnswer(pollId: string): any {
      if (pollId in this.getPollAnswers) {
        return this.getPollAnswers[pollId];
      }
      return {};
    },
    syncPollResults(poll: any) {
      const pollResults = this.getPollAnswer(poll.id).choices as any;
      this.$store.dispatch('presenter/syncPollResults', {
        tvResults: pollResults,
        pollId: poll.id,
      });
    },
    calculatedVotes(pollId: string) {
      let count = 0;
      if (this.getPollAnswers && this.getPollAnswers[pollId]) {
        if (this.getPollAnswers[pollId].type == 'CHOICE') {
          const pollResult = this.getPollAnswers[pollId];

          for (const choice in pollResult['choices']) {
            count += pollResult['choices'][choice].count;
          }
        } else if (this.getPollAnswers[pollId].type == 'SLIDER') {
          return this.getPollAnswers[pollId].count;
        }
      }
      return count;
    },
    openTVPollLink(pollId: string) {
      window.open(
        `${process.env.VUE_APP_BASE_TV_OUT}j/${this.shareToken}/${pollId}/chart`,
        '_blank'
      );
    },
  },
});
